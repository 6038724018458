"use client";

import { useEffect } from "react";
import "../styles/booking.css";
import Script from "next/script";

function BookingForm() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://static.travelthru.com/public/booking-script/assets/index.js";
    // "/assets/index.js";
    script.async = true;
    // @ts-ignore
    document?.getElementById("booking-script-root").appendChild(script);


    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = "https://static.travelthru.com/public/booking-script/assets/index.css";
    // "/assets/index.css";
    document.head.appendChild(link);
  }, []);

  return (
    <>
      <Script>
        {`
          window.ttbsConfig = {
            isHorizontal: false,
            bookingPageUrl: "/booking",
            completePageUrl: "/complete-booking",
            termsAndConditionsUrl: "https://www.travelthru.com/terms-and-conditions",
            clientId: "07f19cb1-94fa-43fa-ad68-d017547c11b8",
            companyClientId: "cea3ed8e-8cfe-4f1e-9666-8e595e83bae2",
            companyCompanyId: "e3f9eca9-c7c1-4717-8a0c-45505f9ecc37",
            locale: "en",
            fonts: {
              default: {
                family: "DM Sans",
                source:
                  "https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap",
              },
              heading: {
                family: "DegularDisplay-Semibold",
                source:
                  "https://static.travelthru.com/css?font=degular-complete/degular-display/web/DegularDisplay-Semibold.woff2",
              },
            },
            mapTheme: "light",
            colors: {
              primary: "#000000",
              secondary: {
                100: "#F24F1D",
                200: "#FFB709",
                300: "#FEF5E9",
              },
              third: {
                100: "#6F6F6F",
                200: "#7D7D7D",
                300: "#888888",
                400: "#D9D9D9",
                500: "#F3F3F3",
              },
            },
          }
        `}
      </Script>

      <div id="booking-script-root" className="w-full h-full" />
    </>
  );
}

export default BookingForm;