"use client";

import { useRef, useState } from "react";
import LocationCard from "./LocationCard";
import { useTranslations } from "next-intl";
import { LeftArrowIcon } from "./icons/LeftArrowIcon";

function GlobalCoverage() {
  const t = useTranslations("AirportTransfer");

  const ref = useRef<any>(null);

  const [movedLeft, setMovedLeft] = useState<boolean>(false);

  const handleMove = (move: "left" | "right") => {
    if (move == "right") {
      scroll(-2000);
      setMovedLeft(false);
    } else if (move == "left") {
      scroll(2000);
      setMovedLeft(true);
    }
  };

  const scroll = (scrollOffset: number) => {
    if (ref?.current) {
      ref.current.scrollTo({ left: scrollOffset, behavior: "smooth" });
      console.log(ref.current?.scrollLeft);
    }
  };

  return (
    <section
      className="font-degular-display-medium relative flex flex-col 2xl:flex-row w-full 
      2xl:pt-[90px] pb-[115px] pl-[40px] 2xl:pl-0"
    >
      <div className=" font-degular-display-semibold  2xl:hidden visible pl-[calc((100vw-1280px)/2)] ">
        <p className=" text-nowrap text-[40px] ">{t("global_coverage")}</p>
        <p className=" h5">{t("destinations")}</p>
        <p className=" h5">{t("countries")}</p>
        <p className=" h5">{t("airports")}</p>
      </div>
      <div className="w-full max-w-full relative h-[180px] 2xl:h-[240px] flex flex-row items-center">
        <button
          onClick={() => handleMove("right")}
          className={` absolute left-[22px] z-50 rotate-180 hidden ${!movedLeft ? "2xl:hidden" : "2xl:block"} `}
        >
          <LeftArrowIcon />
        </button>
        <button
          onClick={() => handleMove("left")}
          className={` absolute right-[22px] z-50 hidden ${movedLeft ? "2xl:hidden" : "2xl:block"}`}
        >
          <LeftArrowIcon />
        </button>

        <div
          className="no-scrollbar absolute left-0 top-0 flex 
            w-full flex-row justify-start 2xl:overflow-x-hidden overflow-x-scroll"
          ref={ref}
        >
          <div className="xl:pl-[calc((100vw-1280px)/2)] " />
          <div
            className="  mr-[135px] hidden 2xl:flex flex-col "
          >
            <p className="font-degular-display-semibold text-nowrap text-6xl ">
              {t("global_coverage")}
            </p>
            <p className=" text-[32px] leading-9">{t("destinations")}</p>
            <p className=" text-[32px] leading-9">{t("countries")}</p>
            <p className=" text-[32px] leading-9">{t("airports")}</p>
          </div>

          <div
            className="flex flex-row items-center space-x-[24px] pr-[100px]
               2xl:space-x-[32px] mt-[32px] 2xl:mt-0"
          >
            <LocationCard
              href="/"
              location={t("asia")}
              imgPath="/assets/images/shutterstock_2288604115.jpg"
            />
            <LocationCard
              href="/"
              location={t("europe")}
              imgPath="/assets/images/15422371092_d2be45b4c1_b.jpg"
            />
            <LocationCard
              href="/"
              location={t("america")}
              imgPath="/assets/images/landmarks-in-south-america-christ-the-redeemer-brazil-1024x767.png"
            />
            <LocationCard
              href="/"
              location={t("africa")}
              imgPath="/assets/images/istockphoto-496777728-612x612.jpg"
            />
            <LocationCard
              href="/"
              location={t("oceania")}
              imgPath="/assets/images/New-Zealand-Film-Photography-South-Island-0001.jpg"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default GlobalCoverage;
